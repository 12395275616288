 
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm'
import NprogressContainer from 'vue-nprogress/src/NprogressContainer'
import Transitions from 'vue2-transitions'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'
import vue2Dropzone from "vue2-dropzone";
import PrimeVue from "primevue/config";
import Password from "primevue/password";
import Ripple from "primevue/ripple";
import Divider from "primevue/divider";
import Dialog from "primevue/dialog";
import InputNumber from "primevue/inputnumber";
import Dropdown from "primevue/dropdown";
import Badge from "primevue/badge";
import Multiselect from "vue-multiselect";
import Button from "primevue/button";
import Calendar from "primevue/calendar";

import './styles/style.bundle.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'primeicons/primeicons.css'
import "vue-select/dist/vue-select.css";

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { VueEditor } from "vue2-editor";

// Components
require('./rule')
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('NprogressContainer', NprogressContainer)
Vue.component('Toast', Toast)
Vue.component('InputText', InputText)
Vue.component('Textarea', Textarea)
Vue.component("VueDropzone", vue2Dropzone);
Vue.component("Password", Password);
Vue.component("Divider", Divider);
Vue.component("Dialog", Dialog);
Vue.component("InputNumber", InputNumber);
Vue.component("Dropdown", Dropdown);
Vue.component("Badge", Badge);
Vue.component("Multiselect", Multiselect);
Vue.component("Button", Button);
Vue.component("Calendar", Calendar);
Vue.component("VueEditor", VueEditor);

// Use
Vue.use(PrimeVue, { ripple: true });
Vue.use(BootstrapVue)
Vue.use(Transitions)
Vue.use(ToastService)

Vue.directive("ripple", Ripple);
Vue.config.productionTip = false
window.Vue = require("vue").default;


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
