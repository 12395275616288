import Crypto from "crypto-js";
import VueCookies from "vue-cookies";

const TOKEN_KEY = "access_token";
const SECRET_KEY = "secret_key";
const PROFILE_KEY = "profile_key";
const REFRESH_TOKEN_KEY = "refresh_token";
const COOKIES_SECRET = "auth_app_secret";
const COOKIES_TOKEN = "auth_app_token";

const TokenService = {
  getToken() {
    const store = VueCookies.get(COOKIES_TOKEN);
    if (store) {
      try {
        const bytes = Crypto.AES.decrypt(store, COOKIES_TOKEN);
        return bytes.toString(Crypto.enc.Utf8);
      } catch (e) {
        this.removeToken();
      }
    }
    return null;
  },

  getSecret() {
    const store = VueCookies.get(SECRET_KEY);
    if (store) {
      try {
        const bytes = Crypto.AES.decrypt(store, SECRET_KEY);
        return bytes.toString(Crypto.enc.Utf8);
      } catch (e) {
        this.removeSecret();
      }
    }
    return null;
  },

  getProfile() {
    const store = localStorage.getItem(PROFILE_KEY);
    if (store) {
      try {
        const bytes = Crypto.AES.decrypt(store, PROFILE_KEY);
        return JSON.parse(bytes.toString(Crypto.enc.Utf8));
      } catch (e) {
        this.removeToken();
      }
    }
    return null;
  },

  saveToken(accessToken) {
    const storage = Crypto.AES.encrypt(accessToken, COOKIES_TOKEN).toString();
    VueCookies.set(COOKIES_TOKEN, storage);
  },

  saveSecret(secretKey) {
    const storage = Crypto.AES.encrypt(secretKey, SECRET_KEY).toString();
    VueCookies.set(COOKIES_SECRET, storage);
  },

  saveProfile(secretKey) {
    secretKey = JSON.stringify(secretKey);
    const storage = Crypto.AES.encrypt(secretKey, PROFILE_KEY).toString();
    localStorage.setItem(PROFILE_KEY, storage);
  },

  removeToken() {
    // localStorage.removeItem(TOKEN_KEY);
    VueCookies.remove(COOKIES_TOKEN);
  },

  removeSecret() {
    // localStorage.removeItem(SECRET_KEY);
    VueCookies.remove(SECRET_KEY);
  },

  removeProfile() {
    // localStorage.removeItem(PROFILE_KEY);
    VueCookies.remove(PROFILE_KEY);
  },

  getRefreshToken() {
    const store = localStorage.getItem(REFRESH_TOKEN_KEY);
    if (store) {
      try {
        const bytes = Crypto.AES.decrypt(store, REFRESH_TOKEN_KEY);
        return bytes.toString(Crypto.enc.Utf8);
      } catch (e) {
        this.removeRefreshToken();
      }
    }
    return null;
  },

  saveRefreshToken(refreshToken) {
    const storage = Crypto.AES.encrypt(
      refreshToken,
      REFRESH_TOKEN_KEY
    ).toString();
    localStorage.setItem(REFRESH_TOKEN_KEY, storage);
  },

  removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  },

  removeProfile() {
    localStorage.removeItem(PROFILE_KEY);
  },

  removeAll() {
    localStorage.clear();
  },
};

export { TokenService };
