import ApiData from "@/api/server/modules/api.data";
import { TokenService, AuthenticationError } from "@/services";

export default {
  namespaced: true,
  state: { user: null, isLoggedIn: false },
  mutations: {
    set_user(state, data) {
      state.user = data;
      state.isLoggedIn = true;
    },
    reset_user(state) {
      state.user = null;
      state.isLoggedIn = false;
    },
  },
  getters: {
    isLoggedIn(state) {
      return state.isLoggedIn;
    },
    user(state) {
      return state.user;
    },
  },
  actions: {
    async login({ dispatch, commit }, { email, password, router }) {
      const requestData = {
        method: "post",
        url: "api/login",
        data: {
          email: email,
          password: password,
        },
      };

      try {
        const response = await ApiData.customRequest(requestData);

        const dta = response.data;
        TokenService.saveToken(dta.token);
        TokenService.saveProfile(dta.data); 
        ApiData.setHeaders(`Bearer ${dta.token}`);
        return dta;
      } catch (error) {
        if (error.response.status === 401) {
          throw new AuthenticationError(401, "Login Failed");
        } else if (error.response.status === 500) {
          throw new AuthenticationError(500, "Network Problem");
        } else {
          throw new AuthenticationError(
            error.response.status,
            error.response.message
          );
        } 
      }
    },
    logout({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        ApiData.post("/profile/logout")
          .then((res) => {
            TokenService.removeToken();
            TokenService.removeAll();
            resolve(res.status);
          })
          .catch((err) => {
            TokenService.removeToken();
            TokenService.removeAll();
            resolve(err.response.status);
            reject(err.response.status);
          });
      });
    },
  },
};
